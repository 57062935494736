// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OnboardingStatus_OnboardingStatus_doorDashMenuLink__\\+Kesv{gap:.5rem;margin:0;margin:initial;margin-top:.6875rem;font-weight:500;line-height:inherit;text-decoration:none}.OnboardingStatus_OnboardingStatus_dangerSolidIconTriangle__kr4T3{align-self:center;margin:.9375rem 0}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/onboarding_status/OnboardingStatus.module.scss"],"names":[],"mappings":"AACI,4DACI,SAAA,CACA,QAAA,CAAA,cAAA,CACA,mBAAA,CACA,eAAA,CACA,mBAAA,CACA,oBAAA,CAGJ,kEACI,iBAAA,CACA,iBAAA","sourcesContent":[".OnboardingStatus {\n    &_doorDashMenuLink {\n        gap: 0.5rem;\n        margin: unset;\n        margin-top: 0.6875rem;\n        font-weight: 500;\n        line-height: unset;\n        text-decoration: none;\n    }\n\n    &_dangerSolidIconTriangle {\n        align-self: center;\n        margin: 0.9375rem 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OnboardingStatus_doorDashMenuLink": `OnboardingStatus_OnboardingStatus_doorDashMenuLink__+Kesv`,
	"OnboardingStatus_dangerSolidIconTriangle": `OnboardingStatus_OnboardingStatus_dangerSolidIconTriangle__kr4T3`
};
export default ___CSS_LOADER_EXPORT___;
