import React, { Dispatch, SetStateAction, useState } from 'react';

import { ReusableModal } from '../common';
import { PriceMarkup } from './PriceMarkup.component';
import styles from './PriceMarkup.module.scss';

interface IPriceMarkupModal {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setLocationModal: Dispatch<SetStateAction<boolean>>;
    isButtonDisable: boolean;
}

export function PriceMarkupModal({
    isOpen = false,
    setIsOpen,
    setLocationModal,
    isButtonDisable,
}: IPriceMarkupModal) {
    const [clickedButton, setClickedButton] = useState<string>('');

    return (
        <>
            <ReusableModal
                isOpen={isOpen}
                title="Markup Marketplace Prices"
                cancelText="Skip for now "
                onCancel={() => {
                    setIsOpen(false);
                    setLocationModal(true);
                    setClickedButton('');
                }}
                confirmText="Continue"
                onConfirm={() => setClickedButton('Continue')}
                className={styles.MarkupPriceModal}
                isFooterBtnsDisabled={isButtonDisable}
            >
                <PriceMarkup
                    isModal={true}
                    setIsOpen={setIsOpen}
                    clickedButton={clickedButton}
                    setClickedButton={setClickedButton}
                    setLocationModal={setLocationModal}
                />
            </ReusableModal>
        </>
    );
}
