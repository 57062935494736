import React from 'react';
import { Banner, Hyperlink, Text } from 'spoton-lib';

import { useAppSelector } from 'api/store';
import { OnboardingStatus } from 'features/merchant_onboarding/MerchantOnboarding.types';
import { BannerInfo } from './InformationBanner.types';
import { URL } from 'utils/constants';

export function InformationBanner() {
    const { onboardingStatus, isError, isActivationRequested } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    const getCurrentBannerInfo = (): BannerInfo => {
        if (onboardingStatus === OnboardingStatus.MENU_REQUESTED) {
            return {
                title: 'Your menu is being synced to DoorDash.',
                message: (
                    <Text>
                        This may take a few minutes. If you’re waiting for more
                        than 10 minutes,
                        <Hyperlink
                            style={{
                                color: '#000000',
                            }}
                            href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                            target="_blank"
                        >
                            contact support.
                        </Hyperlink>
                    </Text>
                ),
            };
        }
        if (onboardingStatus === OnboardingStatus.MENU_BLOCK) {
            return {
                title: 'We were unable to sync your menu',
                message: (
                    <Text>
                        Try again or
                        <Hyperlink
                            style={{
                                color: '#000000',
                            }}
                            href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                            target="_blank"
                        >
                            contact support.
                        </Hyperlink>
                    </Text>
                ),
            };
        }
        if (isActivationRequested) {
            return {
                message: 'Integration activation is in progress',
            };
        }
    };

    const bannerInfo = getCurrentBannerInfo();
    if (bannerInfo) {
        return (
            <Banner
                title={bannerInfo.title}
                message={bannerInfo.message}
                variant={isError ? 'danger' : 'informative'}
            />
        );
    } else {
        return null;
    }
}
