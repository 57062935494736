import React from 'react';
import { Hyperlink, Icon, Text } from 'spoton-lib';

import { useAppSelector } from 'api/store';
import { getConfigVar, ReusableModal } from 'features/common';
import styles from './BusinessHoursModal.module.scss';

interface IBusinessHoursModal {
    isBusinessHoursModalOpen: boolean;
    setIsBusinessHoursModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUserTriesToSetBusinessHours: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

export function BusinessHoursModal(props: IBusinessHoursModal) {
    const {
        isBusinessHoursModalOpen,
        setIsBusinessHoursModal,
        setIsUserTriesToSetBusinessHours,
    } = props;
    const { isBusinessHoursExist } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    const onConfirm = () => {
        if (isBusinessHoursExist) {
            window.location.href = getConfigVar(
                'REACT_APP_DOORDASH_SIGNIN_URL',
            );
        } else {
            setIsUserTriesToSetBusinessHours(true);
            window.open('/new_settings/general', '_blank');
        }
    };
    return (
        <ReusableModal
            isOpen={isBusinessHoursModalOpen}
            title={
                isBusinessHoursExist
                    ? 'Confirm your business hours'
                    : 'We are missing your business hours'
            }
            confirmText={isBusinessHoursExist ? 'Continue' : 'Set up hours'}
            onConfirm={onConfirm}
            onCancel={() => setIsBusinessHoursModal(false)}
        >
            {isBusinessHoursExist ? (
                <>
                    <Text>
                        We need to make sure your business hours are up to date
                        before proceeding. Confirm they are correct and then
                        continue.
                    </Text>
                    <Hyperlink
                        className={
                            styles.BusinessHoursModal_businessHoursPageLink
                        }
                        target="_blank"
                        color="#1C5DCD"
                        href="/new_settings/general"
                    >
                        View Business Hours
                        <Icon size={18} name="ExternalLinkIcon" />
                    </Hyperlink>
                </>
            ) : (
                <Text>
                    We can’t set up DoorDash without your business hours. Can
                    you add them now?
                </Text>
            )}
        </ReusableModal>
    );
}
