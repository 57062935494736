import * as React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { setAppElement } from 'spoton-lib';

import { configureApi } from './utils/axios';
import { ROOT_ELEMENT_ID } from 'utils/constants';
import { App } from 'app';
import { checkIsProdOrStaging, getConfigVar } from 'features';
import './global.css';

// Setup for Datadog RUM Browser Monitoring
if (checkIsProdOrStaging()) {
    datadogRum.init({
        applicationId: getConfigVar('REACT_APP_DATADOG_APPLICATION_ID'),
        clientToken: getConfigVar('REACT_APP_DATADOG_CLIENT_TOKEN'),
        site: 'datadoghq.com',
        env: getConfigVar('REACT_APP_NODE_ENV'),
        service: 'doordash-frontend',
        version: getConfigVar('REACT_APP_VERSION'),
        trackInteractions: true,
    } as RumInitConfiguration);
    datadogRum.startSessionReplayRecording();
}

interface IDashboardProps {
    /**
     * Is true when running in a Dashboard FE ephemeral
     */
    isEphemeralEnv?: boolean;
    merchantId: string;
    businessLocationId: string;
}

const reactLifecycles = singleSpaReact({
    React,
    ReactDOM,
    renderType: 'render',
    loadRootComponent: (props: IDashboardProps) => {
        return Promise.resolve(() => (
            <App businessLocationId={props.businessLocationId} />
        ));
    },
    errorBoundary() {
        return <div>Oops! Something went wrong.</div>;
    },
});

// Single SPA lifecycle methods
export const bootstrap = (props: IDashboardProps) => {
    configureApi(Boolean(props.isEphemeralEnv));

    const rootElement = document.getElementById(ROOT_ELEMENT_ID);
    if (rootElement) {
        setAppElement(rootElement);
    }

    return reactLifecycles.bootstrap(props);
};

export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
