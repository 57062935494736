import React from 'react';
import { Button } from 'spoton-lib';

import Styles from './RoundedButton.module.scss';

interface IRoundedButton {
    onClick: () => void;
    buttonText: string;
    variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost';
}

export function RoundedButton({
    onClick,
    buttonText,
    variant = 'primary',
}: IRoundedButton) {
    return (
        <Button
            variant={variant}
            onClick={onClick}
            className={`${Styles[`RoundedButton_${variant}Button`]} ${Styles.RoundedButton}`}
        >
            {buttonText}
        </Button>
    );
}
