import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    GetMarkupPriceRequest,
    IPriceMarkupSliceState,
    SetMarkupPriceRequest,
} from './PriceMarkup.types';
import * as API from './PriceMarkup.api';

export const initialState: IPriceMarkupSliceState = {
    loading: false,
    isInitialPageLoading: true,
    priceUpliftPercentage: '',
};

export const markupPriceSlice = createSlice({
    name: 'markupPrice',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setIsInitialPageLoading: (
            state,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.isInitialPageLoading = payload;
        },
    },
});

export const { setLoading, setIsInitialPageLoading } = markupPriceSlice.actions;

export const getMarkupPrice = createAsyncThunk(
    'markupPrice/getMarkupPrice',
    async (request: GetMarkupPriceRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.getMarkupPrice(request);
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const setMarkupPrice = createAsyncThunk(
    'markupPrice/setMarkupPrice',
    async (request: SetMarkupPriceRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.setMarkupPrice(request);
        console.log('--------- response----------', response);
        await dispatch(setLoading(false));
        if (response?.status !== 'success') {
            console.log('---rejecting values : ', response.errors);
            return rejectWithValue(response.errors);
        }
    },
);
