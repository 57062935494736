export const ENV = {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
    STAGING: 'staging',
    TEST: 'test',
    /**
     * ephemeral environment
     */
    UNLIMITED: 'unlimited',
};

/**
 * A helper for getting an env variable.
 *
 * When our project gets built for production we switch from the usual build
 * time ENV vars to run time variables injected via our build scripts, nginx,
 * and including it in the projects header.
 *
 * This allows us to set ENV vars whenever we want without the need to rebuild
 * and deploy, however it does mean a little extra work to get the right thing.
 * This method helps take out the guesswork, and gives a nice warning if a
 * variable is not set properly.
 *
 * @see /public/index.html
 * @see /env.sh
 */
export function getConfigVar(key: string): string {
    let env = process.env; // default env
    let configVar = '';
    let isProd = false;

    const isRuntimeEnv = env.NODE_ENV === ENV.PRODUCTION;
    const isTestEnv = env.NODE_ENV === ENV.TEST;

    if (isRuntimeEnv) {
        env = (window as any)._doordash_frontend_env_;
        isProd = env.REACT_APP_NODE_ENV === ENV.PRODUCTION;
    }

    if (env[key] != undefined) {
        configVar = env[key] as string;
    } else if (!isProd && !isTestEnv) {
        console.warn(`[Warning] Environment variable '${key}' is not set`);
    }

    return configVar;
}

/**
 * A helper for checking if the environment is prod or staging
 * @returns {boolean}
 */
export const checkIsProdOrStaging = (): boolean => {
    const envs = [ENV.PRODUCTION, ENV.STAGING];

    return envs.includes(getConfigVar('REACT_APP_NODE_ENV'));
};

/**
 * A helper for checking if the environment is development or ephemeral
 *
 * @returns {boolean}
 */
export const checkIsDevOrEphemeral = (): boolean => {
    const envs = [ENV.DEVELOPMENT, ENV.UNLIMITED];

    return envs.includes(getConfigVar('REACT_APP_NODE_ENV'));
};

/**
 * Prefixes react router routes if the app is being loaded as an MFE in Dashboard.
 */
export function getRoutePrefix(): string {
    if (!window.applicationBase) {
        return '';
    }

    const appUrl = new URL(window.applicationBase);
    return appUrl.pathname.replace(/\/$/, '');
}

export function getBffBaseUrl(isEphemeral: boolean): string {
    return isEphemeral
        ? getConfigVar('REACT_APP_BFF_EPHEMERAL_BASE_URL')
        : getConfigVar('REACT_APP_BFF_BASE_URL');
}

export function getCoreLoginBaseUrl(isEphemeral: boolean): string {
    return isEphemeral
        ? getConfigVar('REACT_APP_CORE_LOGIN_EPHEMERAL_BASE_URL')
        : getConfigVar('REACT_APP_CORE_LOGIN_BASE_URL');
}
